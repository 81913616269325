const strings = {
  findPro: 'Trouver un professionnel',
  myNeed: 'Mon besoin',
  iNeed: "J'ai besoin",
  equipmentType: "Type d'appareil",
  iLive: 'Je réside',
  search: 'Rechercher',
  userSpace: 'Espace client',
  contactUs: 'Contacter Axenergie France',
  youContactUs: 'Contactez-nous',
  connection: 'Connexion',
  joinNetwork: 'Rejoindre le réseau',
  pro: 'Professionnel',
  pleaseJoin: 'Rejoignez le réseau',
  zipcode: 'Code postal',
  advicesAndTips: 'Les conseils & astuces Axenergie',
  advicesAndTipsSubtitle:
      'Axenergie vous accompagne dans votre confort thermique au quotidien, '
      + 'nous vous délivrons ici des conseils et astuces pour vos projets de rénovations'
      + ' et pour l’utilisation de vos appareils de chauffage et climatisation.',
  findYourHeatingPro: 'Trouver votre chauffagiste',
  findMyHeatingPro: 'Trouver mon chauffagiste',
  heatingProIn: 'Chauffagiste à',
  findYourHeatingProSubtitle:
      'Pour votre tranquillité et votre sécurité faites confiance aux entreprises qualifiées du réseau Axenergie, '
      + 'réseau d’experts en chauffage et climatisation, dont la vocation est l’efficacité énergétique',
  back: 'Retour',
  advicesAndNews: 'Conseils et actus',
  allAdvices: 'Tous nos conseils',
  openingHours: 'Horaires de votre chauffagiste',
  followUs: 'Suivez-nous',
  ourInterventionZone: 'Notre zone d\'intervention',
  seeNumber: 'Voir le numéro',
  beCalled: 'Nous contacter',
  whoAreWe: 'Qui sommes nous ? ',
  checkCGV: 'Consulter nos CGV',
  ourNews: 'Nos actualités',
  agencyReviews: 'Avis clients sur ',
  reviewRequest: 'Votre avis nous intéresse',
  reviewRequestSubtitle: "Afin d'améliorer nos services, Vous pouvez nous donner votre avis.",
  reviewRequestCta: 'Laisser un avis',
  homeReviewsTitle: 'Avis client',
  from: 'à partir de',
  subscribe: 'Souscrire',
  learnMore: 'En savoir plus',
  showPrestationTitle: 'votre expert local pour votre bien être thermique !',
  showPrestationSubTitle: 'Véritable spécialiste du chauffage et de la climatisation nous intervenons'
      + ' aussi sur la production d’eau chaude sanitaire et la ventilation (VMC).',
  allPrestations: 'Toutes nos prestations',
  searchPageSubtitle: 'Avec 120 agences indépendantes et plus de 1 100 techniciens qualifiés,'
      + ' le réseau Axenergie est à votre service pour tous vos besoins en chauffage et climatisation.\n'
      + 'Pour être conseillé sur nos prestations ou sur le choix d’un appareil, '
      + 'prenez directement contact avec l’entreprise Axenergie la  plus proche de chez vous!\n',
  noResult: 'Aucun résultat',
  searchEngineDoSearch: 'Veuillez sélectionner vos besoins ci dessus pour afficher les résultats',
  searchEngineEmptyResult: 'Aucune agence Axenergie ne couvre actuellement votre ville.',
  yourAxenergieHeatingPro: 'Votre chauffagiste Axenergie',
  imDown: 'Je suis en panne',
  saveTheDate: 'Prendre rendez-vous',
  prestationPriceWarning: 'Le prix peut varier en fonction de la '
    + 'puissance, du type de votre appareil et de votre lieu d’habitation.',
  fiveGoodReasons: '5 bonnes raisons',
  ourOtherContracts: 'Les autres contrats',
  yourDemand: 'Votre demande',
  name: 'Nom',
  firstname: 'Prénom',
  phone: 'Téléphone',
  email: 'Email',
  address: 'Adresse',
  city: 'Ville',
  message: 'Message',
  errors: {
    pleaseFillField: 'Veuillez remplir ce champ',
    pleaseFillFieldWithRightFormatFile: 'Veuillez remplir ce champ avec un fichier au bon format',
    postDemand: 'Une erreur est survenue lors de l\'envoi de votre demande, merci de réessayer plus tard.',
    payment: 'Une erreur est survenue lors de votre paiement, merci de réessayer plus tard.',
    amountToLow: 'Le montant saisi est trop bas, merci de saisir un autre montant.',
    amountToHigh: 'Le montant saisi est trop haut, merci de saisir un autre montant.',
    invoiceNumberTooLong: 'Le numéro de facture saisi est trop long, merci d\'en saisir un autre.',
    invalidCaptcha: 'Le captcha saisi est invalide, merci d\'en saisir un autre.',
    postDemandZipcode: 'Votre code postal n\'est pas couvert par l\'agence que vous essayez de contacter.'
        + ' Veuillez choisir une autre agence.',
    apply: 'Une erreur est survenue lors de l\'envoi de votre candidature, merci de réessayer plus tard.',
  },
  success: {
    postDemand: 'Votre demande a bien été envoyé ! Vous allez recevoir un mail la résumant.',
    apply: 'Votre candidature a bien été envoyée !',
    payment: 'Votre paiement a été effectué avec succès',
  },
  optinNewsletter: 'Je souhaite recevoir des offres de la part de Axenergie',
  optinRgpd: 'J\'ai lu et j\'accepte les conditions relatives à la protection des données personnelles',
  send: 'Envoyer',
  contactAxenergieNetwork: 'Contactez le siège Axenergie',
  contactAgencySubtitle: 'Quelque soit votre demande, nous vous apporterons une réponse dans les plus bref délais.',
  contactAgencyRgpd: 'Les informations ci-dessus sont collectées pour permettre '
      + 'aux équipes d\'Axenergie de vous contacter, '
      + 'de réaliser un suivi et de vous accompagner dans les prestations de services proposées et souscrites. '
      + 'Vous disposez d\'un droit d\'accès, de rectification et d\'opposition, '
      + 'conformément à notre',
  contactNetworkSubtitle: 'Vous souhaitez nous contacter pour des renseignements commerciaux, '
      + 'une demande auprès du service clients, … '
      + 'Nous nous engageons à traiter votre demande dans les meilleurs délais.\n'
      + 'Si vous souhaitez contacter un chauffagiste, ',
  goTherePlease: 'merci de vous rendre sur cette page.',
  contactNetworkRgpd: 'Vous pourrez facilement vous désinscrire à tout moment Via les liens de '
      + 'désinscription présents dans chacun de nos emails\n'
      + 'Les informations qui vous concernent sont destinées à la Société AXENERGIE. '
      + 'Elles seront utilisées à des fins strictement professionnelles afin de traiter votre demande'
      + ' et de vous recontacter le cas échéant..\n'
      + 'Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, '
      + 'vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, '
      + 'que vous pouvez exercer en vous adressant à AXENERGIE Paris – À l’attention du webmestre du site internet '
    + '– 11 avenue Philippe Auguste 75011 PARIS.\n'
      + 'Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.',
  jobOfferPageTitle: 'Rejoignez les équipes du réseau Axenergie\n',
  jobOfferPageSubtitle: 'Axenergie regroupe des entreprises indépendantes sur le territoire national'
      + ' avec plus de 1 100 techniciens qui interviennent chaque jour chez les particuliers '
      + 'pour assurer l’installation le dépannage, la maintenance ou le remplacement des '
      + 'systèmes de chauffage toutes énergies.',
  spontaneousApplicationTitle: 'Les métiers et offres d\'emploi du réseau Axenergie',
  spontaneousApplicationDescriptions: [
    'Technicien·ne de maintenance chauffage',
    'Technicien·ne de maintenance Froid (Pac-Clim)',
    'Plombier·e chauffagiste',
    'Personnel administratif : secrétaire, chargé·e d’affaire',
    'Chef·fe d’équipe maintenance SAV chauffage',
  ],
  jobOfferDomainSubtitleStart: 'Découvrez également',
  jobOfferDomainSubtitleLinkLabel: 'nos autres types d\'emplois',
  jobOfferDomainSubtitleEnd: 'pour rejoindre les équipes Axenergie',
  jobOfferDomainJobListTitle: 'Découvrez nos offres d\'emploi',
  sendYourSpontaneousApplication: 'Envoyez votre candidature spontanée',
  sendSpontaneousApplication: 'Envoyer une candidature spontanée',
  ourJobOffers: 'Nos offres d\'emploi',
  geographicSector: 'Secteur géographique',
  region: 'Région',
  publishOn: 'Publié le',
  seeAnnouncement: 'Voir l\'annonce',
  axenergieNetwork: 'Réseau Axenergie',
  jobApply: 'Postuler',
  mister: 'Monsieur',
  miss: 'Madame',
  yourFiles: 'Vos Fichiers',
  jobApplyRgpd: 'Les informations qui vous concernent sont destinées à la Société AXENERGIE. '
      + 'Elles seront utilisées à des fins strictement professionnelles afin de traiter votre demande'
      + ' et de vous recontacter le cas échéant..\n'
      + 'Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, '
      + 'vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, '
      + 'que vous pouvez exercer en vous adressant à AXENERGIE Paris – À l’attention du webmestre du site internet '
    + '– 11 avenue Philippe Auguste 75011 PARIS.\n'
      + 'Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.',
  YourContactInfo: 'Vos coordonnées',
  applyJobPleaseFillForm: 'Veuillez remplir ce formulaire en insérant votre CV et votre lettre de motivation.',
  sendMyApplication: 'Envoyer ma candidature',
  yourCV: 'Votre CV',
  yourMotivation: 'Votre lettre de motivation',
  jobApplyFileAcceptance: '(Formats .pdf .doc .docx, max 2Mo)',
  prestationPageBreakdownBlock: {
    advices: {
      title: 'Vous avez un projet de rénovation thermique ?',
      subtitle: '',
      argumentation: [
        'Passez par des professionnel RGE de Proximité',
        'Faites réaliser une étude technique de votre logement',
        'Faites vous accompagner dans le montage de vos dossiers d’obtention des aides à la rénovation énergétique',
        'Exigez une mise en service et le bon de garantie constructeur',
        'Exigez un devis détaillé et précis',
      ],
      description: '',
    },
    heatPump: {
      SAV: {
        title: 'Votre Pompe à Chaleur est en panne',
        subtitle: 'Pensez à vérifier',
        argumentation: [
          'Vérifiez la pression d’eau (1.5 Bars)',
          'Vérifiez votre disjoncteur électrique',
          'Vérifiez et remplacez les piles du thermostat d’ambiance',
          'Contrôlez la consigne de température paramétrée',
          'Contrôlez le mode de fonctionnement : Chauffage ou Eau Chaude Sanitaire seul.',
        ],
        description: '',
      },
      installation: {
        title: 'Vous souhaitez installer une pompe à chaleur ?',
        subtitle: '',
        argumentation: [
          'Un projet PAC réclame une étude, une installation et une maintenance professionnelle spécialisée.',
          'Passez par un professionnel RGE Qualipac',
          'Ne signez Jamais sur une Foire',
          'Etudier la faisabilité et la meilleure implantation de l’unité extérieure '
          + 'et intérieure en fonction de l’impact sonore, '
            + 'l’espace nécessaire et des contraintes de raccordement.',
          'Contrôler la tension électrique et sa stabilité',
          'Bénéficiez d’aides à la rénovation énergétique',
          'Exigez un rapport de mise en service conforme aux prescriptions du constructeur de la PAC.',
        ],
        description: 'Nous proposons un comparatif technique et économique, '
            + 'chiffré et objectif, entre les solutions possibles en intégrant les aides.',
      },
    },
    airConditioner: {
      SAV: {
        title: 'Votre climatisation est en panne ?',
        subtitle: 'Pensez à vérifier',
        argumentation: [
          'Vérifiez et remplacer les piles de la télécommande',
          'Vérifiez votre disjoncteur électrique',
          'Vérifiez le mode de fonctionnement (Chaud/Heat ou Froid/Cool) Eviter le mode automatique',
          'Vérifiez que le mode de fonctionnement est identique sur chaque unité intérieure d’un groupe multi split',
          'Contrôlez la consigne de température paramétrée',
        ],
        description: '',
      },
      installation: {
        title: 'Vous souhaitez installer une climatisation ?',
        subtitle: '',
        argumentation: [
          'Faites réaliser une étude technique par un professionnel RGE',
          'N’achetez pas sur une Foire',
          'Bénéficiez d’aides à la rénovation énergétique',
          'Exigez une mise en service et le bon de garantie constructeur',
        ],
        description: '',
      },
    },
    hotWater: {
      SAV: {
        title: '',
        subtitle: '',
        argumentation: [],
        description: '',
      },
      installation: {
        title: '',
        subtitle: '',
        description: '',
      },
    },
    waterSoftener: {
      SAV: {
        title: '',
        subtitle: '',
        argumentation: [],
        description: '',
      },
      installation: {
        title: '',
        subtitle: '',
        description: '',
      },
    },
    gasBoiler: {
      SAV: {
        title: 'Votre chaudière Gaz est en panne ?',
        subtitle: 'Pensez à vérifier',
        argumentation: [
          'Vérifiez votre disjoncteur électrique',
          'Vérifiez la pression d’eau (1.5 Bars)',
          'Vérifiez que vous avez du GAZ',
          'Vérifiez et remplacer les piles du thermostat d’ambiance',
          'Redémarrez votre chaudière : Débranchez la 5 minutes, et relancez là',
          'Vérifiez le mode de fonctionnement sélectionné (Chauffage ou eau chaude seule)',
          'Vérifiez que vos robinets thermostatiques de radiateur soient ouverts à 3 minimum',
        ],
        description: '',
      },
      installation: {
        title: 'Vous souhaitez installer une chaudière GAZ ?',
        subtitle: '',
        argumentation: [
          'Privilégiez les modèles THPE à condensation',
          'Passez par un professionnel RGE',
          'Faites réaliser une étude technique de votre installation',
          'Exigez le certificat de conformité obligatoire',
          'Bénéficiez d’aide à la rénovation énergétique',
        ],
        description: '',
      },
    },
    oilBoiler: {
      SAV: {
        title: 'Votre chaudière Fioul est en panne ?',
        subtitle: 'Pensez à vérifier',
        argumentation: [
          'Vérifiez votre disjoncteur électrique',
          'Vérifiez la pression d’eau (1.5 Bars)',
          'Vérifiez le niveau de fioul dans votre cuve',
          'Vérifiez et remplacez les piles du thermostat d’ambiance',
          'Redémarrez votre chaudière : Débranchez la 5 minutes, et relancez là',
          'Vérifiez le mode de fonctionnement sélectionné (Chauffage ou eau chaude seule)',
          'Vérifiez que vos robinets thermostatiques de radiateur soient ouverts à 3 minimum',
        ],
        description: '',
      },
      installation: {
        title: 'Vous souhaitez installer une chaudière fioul ?',
        subtitle: '',
        argumentation: [],
        description: 'Plusieurs solutions s’offrent à vous pour le remplacement de votre système fioul,\n'
            + 'Après une étude technique de votre habitation, les entreprises du réseau Axenergie vous '
            + 'proposerons une solution efficace,'
            + ' économique et adaptée à votre projet.',
      },
    },
    woodBoiler: {
      SAV: {
        title: 'Votre chaudière Bois/Granulés est en panne ?',
        subtitle: 'Pensez à vérifier',
        argumentation: [
          'Vérifiez votre disjoncteur électrique',
          'Videz le cendrier',
          'Vérifiez la pression d’eau (1.5 Bars)',
          'Vérifiez et remplacer les piles du thermostat d’ambiance',
          'Redémarrez votre chaudière : Débranchez la 5 minutes, et relancez là',
          'Vérifiez le mode de fonctionnement sélectionné (Chauffage ou eau chaude seule)',
          'Vérifiez que vos robinets thermostatiques de radiateur soient ouverts à 3 minimum',
        ],
        description: '',
      },
      installation: {
        title: 'Vous souhaitez installer une chaudière Bois/ Granulé ?',
        subtitle: '',
        argumentation: [
          'Définissez un emplacement d’installation : 9m² minimum avec 2.4m de hauteur sous plafond',
          'Passez par un professionnel RGE Qualibois',
          'Faites réaliser une étude technique de votre installation',
          'Bénéficiez d’aide à la rénovation énergétique',
        ],
        description: '',
      },
    },
    pelletBoiler: {
      SAV: {
        title: 'Votre Poêle à Granulés est en panne ?',
        subtitle: 'Pensez à vérifier',
        argumentation: [
          'L’alimentation électrique',
          'Videz et nettoyer le creuset',
          'Vérifier le niveau de granulé',
          'Remplacez les piles de votre thermostat',
          'Vérifiez la température de consigne demandée',
        ],
        description: '',
      },
      installation: {
        title: 'Vous souhaitez installer un poêle à granulé ?',
        subtitle: '',
        argumentation: [
          'Passez par un professionnel RGE Qualibois',
          'Bénéficiez d’aide à la rénovation énergétique',
          'Prévoir une alimentation électrique à proximité',
        ],
        description: '',
      },
    },
  },
  pageNotFound: 'Page non trouvée',
  notFoundDescription: 'La page que vous recherchez ne semble pas exister. '
      + 'Merci de retourner à l’accueil du site.',
  backHome: 'Retourner à l\'accueil',
  noJobOffer: 'Aucune offre n\'est à pourvoir actuellement',
  rgpd: {
    placeAutocomplete: 'Votre situation géographique est demandée afin de cibler '
        + 'le chauffagiste le plus près de chez vous.'
        + "Vous disposez d'un droit d'accès, de rectification et d'opposition, conformément à notre",
    privacyPolicy: 'politique de confidentialité',
  },
  payBillTo: 'Effectuez un paiement ',
  afterQuote: "Merci d'avoir validé votre devis, vous pouvez payer votre accompte",
  to: 'à',
  pay: 'Payer',
  payBill: 'Payer ma facture',
  billNumber: 'Référence ou numéro de facture',
  nameOnBill: 'Nom indiqué sur la facture',
  amountToPay: 'Montant à payer (€)',
  allFieldsAreRequired: '* Tous les champs sont obligatoires',
  sitemap: 'Plan du site',
  agencies: 'Agences',
  advices: 'Conseils',
  jobOffers: 'Offres d\'emploi',
  editoPages: 'Pages éditoriales',
  payOnline: 'Payer en ligne',
  paymentTo: 'Votre paiement sera effectué à',
  representBy: 'représenté par',
  seeMore: 'Déplier',
  seeLess: 'Replier',
};

export default strings;
